<template>
  <div>
    <div class="qchat-messenger" :style="styles.mainPanel">
      <div class="q-d-flex">
        <Header @close="onPanelClose" />
      </div>
      <div class="qchat-messenger-body">
        <FloatNotification />
        <Form v-if="!user" />
        <transition v-else name="slide-side">
          <Conversation />
        </transition>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
const Header = () => import("../messenger/form/Header.vue");
const Form = () => import("../messenger/form/index.vue");
const Conversation = () => import("../messenger/conversation/index.vue");
const Footer = () => import("./Footer.vue");
const FloatNotification = () => import("../messenger/FloatNotification.vue");

export default {
  components: { Header, Form, Conversation, Footer, FloatNotification },
  computed: {
    ...mapState("messenger", ["user", "styles"])
  },
  methods: {
    onPanelClose() {
      window.parent.postMessage(
        {
          eventName: "onPanelTrigger",
          data: { isOpened: false }
        },
        "*"
      );
      this.$emit("close");
    }
  }
};
</script>
